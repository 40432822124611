import React from 'react';
import DOMPurify from 'dompurify';

const SanitizedHTML = ({ html }) => {
  // Sanitize the HTML content using DOMPurify
  const sanitizedHtml = DOMPurify.sanitize(html);

  // Render the sanitized HTML
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default SanitizedHTML;
