import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { setLocalStorage } from '../components/sessionManager';
import { getNavigationPath } from '../components/navigateExistingUser';
import Menu from '../components/menu';
import Logo from '../components/logo';
import Footer from '../components/footer';
import Heart from '../components/heart';

const Signin = ({ menu }) => {
  const [accessCode, setAccessCode] = useState('');
  const navigate = useNavigate();
  const { loading, error, sendRequest } = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest('/login', 'POST', { accessCode });

      if (typeof response.token === 'string') {
        // Store data at the browser
        setLocalStorage(
          response.stepValue,
          response.token,
          response.orderID,
          24,
        ); // 24 Hours
        
        //TODO: Remove log
        console.log({
          token: response.token,
          orderID: response.orderID,
          step: response.stepValue,
        });

        const navigatePath = getNavigationPath(response.stepValue);
        navigate(navigatePath);
      }
    } catch (error) {
      // Error handling is done inside the useApi hook
    }
  };

  return (
    <>
      <Menu menu={menu} />
      <Logo size={'large'} />
      <form className="flex flex-col items-center mt-8" onSubmit={handleSubmit}>
        {error && <p className="m-2">{error}</p>}
        <input
          className="w-64 py-2 px-4 font-thin rounded border border-gray-300 mb-4 focus:border-gray-200 border-1 focus:outline-none text-center focus:ring focus:ring-thin focus:ring-gray-200"
          type="text"
          placeholder="Enter access code"
          value={accessCode}
          onChange={(e) => setAccessCode(e.target.value)}
          disabled={loading}
        />
        <button
          className="bg-SLblue hover:bg-gray-500 text-white font-bold py-2 px-8 rounded"
          type="submit"
          disabled={loading}
        >
          Sign in!
        </button>
      </form>
      <Footer />
      <Heart color={'blue'} />
    </>
  );
};

export default Signin;
