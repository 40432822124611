import React from 'react';

const Heart = ({ color }) => {
  const colors = {
    red: '#ED1C25',
    blue: '#1B006D',
    gray: '#2B2B2B',
    green: '#286130',
    white: '#FFFFFF',
  };
  return (
    <div className="fixed left-0 bottom-0 w-auto h-auto -z-10">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 462.37 511.52"
        width="300px"
        height="300px"
      >
        <defs>
          <style>
            {`.cls-1 {
                fill: none;
                stroke: ;
                stroke-width: 4px;
                animation: colorTransition 5s infinite;
              }
              @keyframes colorTransition {
                0% {
                  stroke: #fffaef;
                }
                25% {
                  opacity: 1; /* Fade in to full opacity */
                  stroke: ${colors[color]};
                }
                75% {
                  opacity: 1; /* Maintain full opacity for a while */
                  stroke: ${colors[color]};
                }
                100% {
                  stroke: #fffaef;
                }
              }
              `}
          </style>
        </defs>
        <g id="Group_2" data-name="Group 2">
          <path
            id="Path_19"
            data-name="Path 19"
            className="cls-1"
            d="M164.7,4.91L6.86,94.62s124.46,416.91,125.82,416.92,108.94-342.19,108.94-342.19L164.7,4.91Z"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            className="cls-1"
            d="M132.69,511.55L476.39,244.09l-76.93-164.45-157.84,89.72"
          />
        </g>
      </svg>
    </div>
  );
};

export default Heart;
