import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Heart from './heart';

const Menu = ({ menu }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  let menuLinks = [];
  if (menu.length) {
    menuLinks = menu.map((el, i) => (
      <Link
        key={`${el.handle}_${i}`}
        to={`/${el.url}`}
        className="text-white mx-6 my-4"
        onClick={() => setMenuOpen(false)} // Close the menu on link click
      >
        {el.title}
      </Link>
    ));
  }

  return (
    <>
      <nav className={`bg-SLblue w-full fixed ${menuOpen ? 'h-screen' : 'h-20 beveledBottom'} z-50 transition-all duration-300`}>
        <div className="max-w-7xl mx-auto px-4 flex justify-between items-center h-16">
          <div className="flex mx-auto text-lg items-center hidden md:flex">
            <Link to={`/sign-in`} className="text-white mx-6">
              Sign in!
            </Link>
            {menu.length && menuLinks}
          </div>
          <div className="md:hidden">
            <button className="absolute top-4 right-4 text-white" onClick={toggleMenu}>
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
        
        {menuOpen && (
          <div className="fixed inset-0 bg-SLblue flex flex-col items-center z-40">
            <button className="absolute top-4 right-4 text-white" onClick={toggleMenu}>
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="flex flex-col items-center text-2xl space-y-6 mt-40">
              <Link to={`/sign-in`} className="text-white font-bold" onClick={() => setMenuOpen(false)}>
                Sign in!
              </Link>
              {menu.length && menuLinks}
            </div>
            <Heart color={'white'} />
          </div>
        )}
      </nav>
    </>
  );
};

export default Menu;
