// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import useApi from '../../hooks/useApi';

const Payment = () => {
  // const { sendRequest } = useApi();
  // const navigate = useNavigate();
  // const [isGenerating, setIsGenerating] = useState(false);

  // const handleButtonClick = async () => {
  //   setIsGenerating(true); // Disable the button immediately upon click
  //   try {
  //     const response = await sendRequest('/generate_access', 'POST', {
  //       payload: {
  //         selectedProducts: selectedAmount,
  //       },
  //     });
  //     dispatch(setAccessCodes(response.accessCodes));
  //     navigate('/shop/success');
  //   } catch (error) {
  //     console.error('Error generating access codes:', error);
  //   } finally {
  //     setIsGenerating(false); // Re-enable the button after request completes
  //   }
  // };

  // return (
  //   <div>
  //     <h2>Payment</h2>
  //     <p>This is a paragraph inside a div.</p>

  //     <button onClick={handleButtonClick} disabled={isGenerating}>
  //       {isGenerating ? 'Generating...' : 'Mimic successful payment'}
  //     </button>

  //     <Link to="/shop/success">Error in payment process</Link>
  //   </div>
  // );
};

export default Payment;
