import React from 'react';
import { Link } from 'react-router-dom';

const DataOverview = () => {
  return (
    <div>
      <h2>Data overview</h2>
      <p>This is a paragraph inside a div.</p>
      <Link to="/shop/payment">Next</Link>
    </div>
  );
};

export default DataOverview;
