import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo';
import Logout from '../../components/logout';
import useApi from '../../hooks/useApi';
import SanitizedHTML from '../../components/sanitizeHTML';
import StepIndicatorFooter from '../../components/stepIndicatorFooter';
import Heart from '../../components/heart';

const pageHandle = 'shop-sweater-selection';

const MAXORDERAMOUNT = 3;
const AmountSelectionStep = 2;

const AmountSelection = () => {
  const [requestMade, setRequestMade] = useState(false);
  const [page, setPage] = useState(null);

  const navigate = useNavigate();
  const { sendRequest } = useApi();
  const [selectedOption, setSelectedOption] = useState('1');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maxOrderAmountError, setMaxOrderAmountError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRequestMade(true);
      try {
        // Fetch order details
        const page = await sendRequest(
          `/secure_shop_content/${pageHandle}`,
        );

        setPage(page?.node);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    if (!requestMade) {
      fetchData();
    }
  }, [requestMade, sendRequest]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const orderID = JSON.parse(localStorage.getItem('auth')).orderID;
    if (selectedOption > MAXORDERAMOUNT) {
      setMaxOrderAmountError(true);
      setLoading(false);
      return;
    }

    try {
      // Update the step to 2 in the orderInfo collection
      await sendRequest(`/order_info/${orderID}`, 'PUT', {
        step: AmountSelectionStep + 1,
        amount: parseInt(selectedOption),
      });
      // Redirect to the next step or page upon successful submission
      navigate('/shop/size-selection'); // Adjust this to the actual next step or page
    } catch (error) {
      console.error('Error updating order information:', error);
      setError('Failed to update order information');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Logout />
      <div className="contentBackground mt-20 w-full flex justify-center bg-gradient-to-t from-background to-backgroundDark min-h-screen">
        <div className="content w-10/12 mt-20 text-SLgray text-center">
          {page && <SanitizedHTML html={page.body} />}
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
              <div className="align-center">
                <label htmlFor="options">Selecteer een aantal:</label>
                <select
                  id="options"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="mx-auto block mt-1 px-4 py-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                  required
                >
                  <option value="">Select an option</option>
                  <option value="1">1 Sweater</option>
                  <option value="2">2 Sweaters</option>
                  <option value="3">3 Sweaters</option>
                </select>

                <button
                  type="submit"
                  disabled={loading}
                  className={`px-4 mt-4 py-2 bg-SLblue hover:bg-SLgreen text-white rounded-md ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? 'Loading...' : 'Volgende'}
                </button>
              </div>
              {error && <div className="text-red-500">{error}</div>}
              {maxOrderAmountError && (
                <div className="text-red-500">
                  Nice try, please just select 1, 2, or 3 items.
                </div>
              )}
            </form>
        </div>
        <Heart color="red" />
      </div>
      <StepIndicatorFooter step={AmountSelectionStep} />
    </>
  );
};

export default AmountSelection;
