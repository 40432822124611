import React, { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import CharitySelectionBlog from '../../components/charitySelectionBlog';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo';
import SanitizedHTML from '../../components/sanitizeHTML';
import StepIndicatorFooter from '../../components/stepIndicatorFooter';
import Heart from '../../components/heart';
import Logout from '../../components/logout';

const pageHandle = 'shop-charity-selection';
const charitySelectionStep = 4;

const CharitySelection = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const [requestMade, setRequestMade] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [availableCharities, setAvailableCharities] = useState(null);
  const [selectedCharity, setSelectedCharity] = useState(null);
  const { sendRequest } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      setRequestMade(true);
      try {
        const orderID = JSON.parse(localStorage.getItem('auth')).orderID;

        // Fetch order details
        const options = await sendRequest(`/charity/${orderID}`, 'GET');

        setAvailableCharities(options);

        // Fetch page details
        const page = await sendRequest(
          `/secure_shop_content/${pageHandle}`,
        );
        setPage(page?.node);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (!requestMade) {
      fetchData();
    }
  }, [requestMade, sendRequest]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const orderID = JSON.parse(localStorage.getItem('auth')).orderID;
      // Construct the data object to be sent in the request
      const requestData = {
        step: charitySelectionStep + 1,
        charity: selectedCharity,
      };
      // Update the step and selected sizes in the orderInfo collection
      await sendRequest(`/order_info/${orderID}`, 'PUT', requestData);
      // Redirect to the next step or page upon successful submission
      navigate('/shop/shipping-info');
    } catch (error) {
      console.error('Error updating order information:', error);
      setError('Failed to update order information');
    }
  };

  return (
    <>
      <Logo />
      <Logout />
      <div className="contentBackground mt-20 w-full flex justify-center bg-gradient-to-t from-background to-backgroundDark min-h-screen">
        <div className="content w-10/12 mt-20 text-SLgray text-center mb-20">
          {loading ? (
            <p>Loading</p>
          ) : (
            <>
              <div className="m-auto content mb-10">
                {page && <SanitizedHTML html={page.body} />}
              </div>
              <form onSubmit={handleSubmit}>
                {availableCharities &&
                  availableCharities.map((blog, index) => (
                    <CharitySelectionBlog
                      key={index}
                      id={blog.productOptionId}
                      title={blog.title}
                      content={blog.content}
                      imageUrl={blog.image.originalSrc}
                      metafields={blog.metafields}
                      handleSelectOption={setSelectedCharity}
                    />
                  ))}
                <div className="w-full text-right mt-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`m-auto w-40 px-4 py-2 bg-SLblue hover:bg-SLgreen text-background rounded-md ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? 'Loading...' : 'Volgende'}
                  </button>
                </div>
              </form>
            </>
          )}
          {error && 'An error occured! Please refresh or try again later'}
        </div>
        <StepIndicatorFooter step={charitySelectionStep} />
        <Heart color="red" />
      </div>
    </>
  );
};

export default CharitySelection;
