import { useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (endpoint, method = 'GET', data = null) => {
    setLoading(true);
    setError(null);

    const url = `${apiUrl}${endpoint}`;
    
    try {
      const response = await axios({
        method,
        url,
        data,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      
      setLoading(false);
      const errorMessage = error.response?.data.error
        ? error.response?.data.error
        : 'An error occured, please try again!';
      setError(errorMessage);

      throw error; // Re-throw the error for further handling if needed
    }
  };

  return { loading, error, sendRequest };
};

export default useApi;
