import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';

const Logout = () => {
  const navigate = useNavigate();
  const { sendRequest } = useApi(); // Import the custom hook

  const handleLogout = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('auth')).token;
      // Call the logout endpoint to clear the token
      await sendRequest('/logout', 'POST', { token: token });
      // Clear the authentication token from local storage
      localStorage.removeItem('auth');
      // Redirect the user to the login page
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle error if necessary
    }
  };

  return (
    <Link
      className="fixed top-0 text-xs right-0 arrow border-SLred text-white"
      onClick={handleLogout}
      to="/"
    >
      <div className="-ml-5 mt-6">Logout</div>
    </Link>
  );
};

export default Logout;
