export function getNavigationPath(stepValue) {
  switch (stepValue) {
    case 2:
      return '/shop/amount-selection';
    case 3:
      return '/shop/size-selection';
    case 4:
      return '/shop/charity-selection';
    case 5:
      return '/shop/shipping-info';
    default:
      return '/shop/congratulations'; // Default path if stepValue doesn't match any case
  }
}
