import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../components/menu';
import Logo from '../components/logo';
import Footer from '../components/footer';
import Heart from '../components/heart';

const Home = ({ menu }) => {
  return (
    <>
      <Menu menu={menu} />
      <Logo size={'large'} />
      <h2 className="text-SLblue text-5xl text-center font-bold my-14">
        Happiness through giving
      </h2>
      <Link
        to="/sign-in"
        className="bg-SLblue hover:bg-SLgreen text-white font-bold py-2 px-8 rounded"
      >
        Sign in!
      </Link>
      <div className="testDiv"></div>
      <Footer />
      <Heart color={'blue'} />
    </>
  );
};

export default Home;
