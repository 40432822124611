// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import useApi from '../../hooks/useApi';

const Success = () => {
  // const { sendRequest } = useApi();
  // const [isLoading, setIsLoading] = useState(true);
  // const [markedTokenCompleted, setMarkedTokenCompleted] = useState(false);

  // useEffect(() => {
  //   const markTokenCompleted = async () => {
  //     const token = authToken; // Make sure the token is still usable after deletion from store
  //     dispatch(clearAuthToken()); // This action removes authToken
  //     try {
  //       const response = await sendRequest('/completed', 'POST', {
  //         token: token,
  //       });
  //       if (response.success) {
  //         setMarkedTokenCompleted(true); // Set flag to true indicating token removal completed
  //         localStorage.removeItem('auth'); // Remove token from local storage
  //       } else {
  //         console.warn('Token not removed');
  //       }
  //     } catch (error) {
  //       console.error('Error marking token as completed:', error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (authToken && !markedTokenCompleted) {
  //     markTokenCompleted();
  //   } else {
  //     setIsLoading(false); // If authToken is empty or markedTokenCompleted is true, set loading to false
  //   }
  // }, [authToken, dispatch, markedTokenCompleted, sendRequest]);

  // return (
  //   <div>
  //     <Link to="/">Back to homepage</Link>
  //     {isLoading ? (
  //       <p>Loading...</p>
  //     ) : (
  //       <>
  //         <h1>Purchase successful!</h1>
  //         <p>
  //           Your unique Spread Love sweater(s) will be shipped to you as soon as
  //           possible.
  //         </p>
  //         {/* Display any additional success messages or details here */}
  //       </>
  //     )}
  //   </div>
  // );
};

export default Success;
