import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Heart from '../../components/heart';
import Logo from '../../components/logo';
import SanitizedHTML from '../../components/sanitizeHTML';

import StepIndicatorFooter from '../../components/stepIndicatorFooter';
import useApi from '../../hooks/useApi';
const confetti = require('canvas-confetti');

const pageHandle = 'shop-congratulations';

const Congratulations = () => {
  const { sendRequest } = useApi();
  const [requestMade, setRequestMade] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(null);

  const shootConfetti = () => {
    return Array(5)
      .fill(5)
      .map((el, i) => {
        const randomAngle = Math.random() * 360;
        return setTimeout(
          function () {
            confetti.default({
              particleCount: 400,
              spread: 100,
              origin: { y: 0.6 },
              angle: randomAngle,
            });
          },
          (i + 1) * 500,
        );
      });
  };

  useEffect(() => {
    shootConfetti();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setRequestMade(true);
      try {
        // Fetch order details
        const page = await sendRequest(
          `/secure_shop_content/${pageHandle}`,
        );

        setPage(page?.node);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    if (!requestMade) {
      fetchData();
    }
  }, [requestMade, sendRequest]);

  return (
    <>
      <Logo />
      <div className="contentBackground mt-20 w-full flex justify-center bg-SLblue min-h-screen">
        <div className="content w-10/12 mt-20 text-white text-center">
          {loading ? (
            <p>Loading</p>
          ) : (
            <>
              {page && <SanitizedHTML html={page.body} />}

              <div
                className="bg-gray-500 inline-block hover:bg-SLgreen cursor-pointer text-white font-bold py-2 px-8 rounded m-3"
                onClick={() => shootConfetti()}
              >
                Gimme more
              </div>
              <Link
                className="bg-SLred inline-block hover:bg-SLgreen text-white font-bold py-2 px-8 rounded"
                to="/shop/amount-selection"
              >
                Let's GO!
              </Link>
            </>
          )}
          {error && 'An error occured! Please refresh or try again later'}

          <Heart color="white" />
        </div>
      </div>
      <StepIndicatorFooter step={1} />
    </>
  );
};

export default Congratulations;
