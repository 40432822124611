import React from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from '../images/logo.svg';

const Logo = ({ size }) => {
  let logoSize, marginTop, fixed;
  if (size === 'large') {
    logoSize = 'w-52';
    marginTop = 'mt-20';
    fixed = '';
  } else {
    logoSize = 'w-28';
    marginTop = '';
    fixed = 'fixed';
  }
  return (
    <div className={`${fixed} text-center ${marginTop}`}>
      <Link to="/">
        <img className={`logo ${logoSize} h-auto`} src={LogoSVG} alt="Logo" />
      </Link>
    </div>
  );
};

export default Logo;
