import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-SLred text-white py-2 fixed bottom-0 w-full beveledTop">
      <div className="center">
        <div className="text-center mx-4 mt-6">
          <p className="text-white">Amount raised for charity:</p>
          <p className="text-white text-l font-bold">$50,000</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
