import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import Logo from '../../components/logo';
import SanitizedHTML from '../../components/sanitizeHTML';
import StepIndicatorFooter from '../../components/stepIndicatorFooter';
import Heart from '../../components/heart';
import Logout from '../../components/logout';

const pageHandle = 'shop-shipping-info';
const shippingInfoStep = 5;

const AddressForm = () => {
  const { sendRequest } = useApi();
  const [requestMade, setRequestMade] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    prefix: '',
    lastName: '',
    city: '',
    postalCode: '',
    houseNumber: '',
    address: '',
    suffix: '',
    emailAddress: '',
  });

  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setRequestMade(true);
      try {
        // Fetch order details
        const page = await sendRequest(
          `/secure_shop_content/${pageHandle}`,
        );

        setPage(page?.node);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    if (!requestMade) {
      fetchData();
    }
  }, [requestMade, sendRequest]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'postalCode') {
      const isValid = validatePostalCode(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        postalCode: isValid ? '' : 'Invalid postal code',
      }));
    } else if (name === 'emailAddress') {
      const isValid = /^\S+@\S+\.\S+$/.test(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: isValid ? '' : 'Invalid email address',
      }));
    }
  };

  // Validate postal code
  const validatePostalCode = (postalCode) => {
    const regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
    return regex.test(postalCode);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate each field
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First name is required';
      valid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last name is required';
      valid = false;
    }

    if (!formData.city.trim()) {
      newErrors.city = 'City is required';
      valid = false;
    }

    if (!formData.postalCode.trim()) {
      newErrors.postalCode = 'Postal code is required';
      valid = false;
    } else if (!validatePostalCode(formData.postalCode.trim())) {
      newErrors.postalCode = 'Invalid postal code';
      valid = false;
    }

    if (!formData.houseNumber.trim()) {
      newErrors.houseNumber = 'House number is required';
      valid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
      valid = false;
    }

    if (!formData.emailAddress.trim()) {
      newErrors.emailAddress = 'Email address is required';
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.emailAddress.trim())) {
      newErrors.emailAddress = 'Invalid email address';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const orderID = JSON.parse(localStorage.getItem('auth')).orderID;
        // Construct the data object to be sent in the request
        const requestData = formData;
        // Update the step and selected sizes in the orderInfo collection
        const response = await sendRequest(`/shipping_info/${orderID}`, 'POST', requestData);
        // Redirect to the next step or page upon successful submission
        
        window.location.href = response.checkoutUrl;
      } catch (error) {
        console.error('Error updating order information:', error);
        setSubmitError('Failed to save shipping info');
      }
    }
  };

  return (
    <>
      <Logo />
      <Logout />
      <div className="contentBackground mt-20 w-full flex justify-center bg-gradient-to-t from-background to-backgroundDark min-h-screen">
        <div className="content w-10/12 mt-20 text-SLgray text-left mb-20">
          {loading ? (
            <p>Loading</p>
          ) : (
            <>
              <div className="m-auto content">
                {page && <SanitizedHTML html={page.body} />}
              </div>
              <form
                onSubmit={handleSubmit}
                noValidate
                className="flex flex-col"
              >
                <label className="mb-4">
                  Voornaam
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                  />
                  {errors.firstName && (
                    <span className="text-red-500">{errors.firstName}</span>
                  )}
                </label>
                <div className="mb-4 flex items-center w-full space-x-4">
                  <label className="flex-1">
                    Voorvoegsel
                    <input
                      type="text"
                      name="prefix"
                      value={formData.prefix}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                  </label>
                  <label className="flex-1 basis-1/2">
                    Achternaam
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                    {errors.lastName && (
                      <span className="text-red-500">{errors.lastName}</span>
                    )}
                  </label>
                </div>
                <div className="mb-4 flex items-center w-full space-x-4">
                  <label className="flex-3">
                    Adres
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                    {errors.address && (
                      <span className="text-red-500">{errors.address}</span>
                    )}
                  </label>
                  <label className="flex-1">
                    Huisnummer
                    <input
                      type="text"
                      name="houseNumber"
                      value={formData.houseNumber}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                    {errors.houseNumber && (
                      <span className="text-red-500">{errors.houseNumber}</span>
                    )}
                  </label>
                  <label className="flex-1">
                    Toevoeging
                    <input
                      type="text"
                      name="suffix"
                      value={formData.suffix}
                      onChange={handleChange}
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                  </label>
                </div>
                <div className="mb-4 flex items-center w-full space-x-4">
                  <label className="flex-1">
                    Postcode
                    <input
                      type="text"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                    {errors.postalCode && (
                      <span className="text-red-500">{errors.postalCode}</span>
                    )}
                  </label>
                  <label className="flex-1">
                    Plaatsnaam
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                    />
                    {errors.city && (
                      <span className="text-red-500">{errors.city}</span>
                    )}
                  </label>
                </div>
                <label className="mb-4">
                  E-mailadres
                  <input
                    type="email"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
                  />
                  {errors.emailAddress && (
                    <span className="text-red-500">{errors.emailAddress}</span>
                  )}
                </label>
                {submitError && (
                  <span className="text-red-500">{submitError}</span>
                )}
                <div className="w-full text-right mt-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`m-auto w-40 px-4 py-2 bg-SLblue hover:bg-SLgreen text-background rounded-md ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? 'Loading...' : 'Volgende'}
                  </button>
                </div>
              </form>
            </>
          )}
          {error && 'An error occured! Please refresh or try again later'}
        </div>
        <StepIndicatorFooter step={shippingInfoStep} />
        <Heart color="red" />
      </div>
    </>
  );
};

export default AddressForm;
