import React, { useState, useEffect } from 'react';
import Logo from '../../components/logo';
import Logout from '../../components/logout';
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import SanitizedHTML from '../../components/sanitizeHTML';
import sweaterIcon from '../../images/sweaterIcon.svg';
import StepIndicatorFooter from '../../components/stepIndicatorFooter';
import Heart from '../../components/heart';

const pageHandle = 'shop-size-selection';
const SizeSelectionStep = 3;

const SizeSelection = () => {
  const navigate = useNavigate();
  const { sendRequest } = useApi();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [page, setPage] = useState(null);
  const [amount, setAmount] = useState(1);

  const [requestMade, setRequestMade] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRequestMade(true);
      try {
        const orderID = JSON.parse(localStorage.getItem('auth')).orderID;

        // Fetch order details
        const orderInfoResponse = await sendRequest(
          `/amount_and_sizes/${orderID}`,
          'GET',
        );
        const { sizes, amount } = orderInfoResponse;

        setAmount(amount);
        setAvailableSizes(sizes);

        // Fetch page details
        const pageResponse = await sendRequest(
          `/secure_shop_content/${pageHandle}`,
        );
        setPage(pageResponse?.node);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (!requestMade) {
      fetchData();
    }
  }, [requestMade, sendRequest]);

  // Handle size selection for a dropdown
  const handleSizeChange = (index, size) => {
    setSelectedSizes((prevSelectedSizes) => {
      const newSelectedSizes = [...prevSelectedSizes];
      newSelectedSizes[index] = size;
      return newSelectedSizes;
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const orderID = JSON.parse(localStorage.getItem('auth')).orderID;
      // Construct the data object to be sent in the request
      const requestData = {
        step: SizeSelectionStep + 1,
        sizes: selectedSizes,
      };
      // Update the step and selected sizes in the orderInfo collection
      await sendRequest(`/order_info/${orderID}`, 'PUT', requestData);
      // Redirect to the next step or page upon successful submission
      navigate('/shop/charity-selection');
    } catch (error) {
      console.error('Error updating order information:', error);
      setError('Failed to update order information');
    }
  };

  const amountArray = Array.from({ length: amount }, (_, index) => index + 1);

  const sizeSelection = amountArray.map((e, i) => (
    <div
      key={`amount${i}`}
      className="flex justify-center border-b border-dashed border-SLblue py-6"
    >
      <img
        width="40px"
        height="40px"
        src={sweaterIcon}
        alt="sweaterIcon"
        className="mr-6"
      />
      <div className="pr-4 mt-3">{i + 1}</div>
      <select
        id={`amount${i}`}
        value={selectedSizes[i]}
        onChange={(e) => handleSizeChange(i, e.target.value)}
        className="block mt-1 px-4 py-2 border border-SLblue focus:outline-none focus:ring-SLblue focus:border-SLblue"
      >
        <option value="">Kies de juiste maat</option>
        {availableSizes.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  ));

  return (
    <>
      <Logo />
      <Logout />
      <div className="contentBackground mt-20 w-full flex justify-center bg-gradient-to-t from-background to-backgroundDark min-h-screen">
        <div className="content w-10/12 mt-20 text-SLgray text-center">
          {loading && <div>Loading data</div>}
          {page && <SanitizedHTML html={page.body} />}
          <div className="flex justify-center ">
            <form onSubmit={handleSubmit}>
              {sizeSelection}
              <div className="w-full text-right mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`m-auto w-40 px-4 py-2 bg-SLblue hover:bg-SLgreen text-background rounded-md ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? 'Loading...' : 'Volgende'}
                  </button>
                </div>
            </form>
          </div>
          {error}
        </div>
        <StepIndicatorFooter step={SizeSelectionStep} />
        <Heart color="blue" />
      </div>
    </>
  );
};

export default SizeSelection;
