import React from 'react';
import { Link } from 'react-router-dom';

const StepIndicatorFooter = ({ step }) => {
  // Define step labels and URLs
  const steps = [
    { label: 'Welkom!', url: '/shop/congratulations' },
    { label: 'Aantal', url: '/shop/amount-selection' },
    { label: 'Maten', url: '/shop/size-selection' },
    { label: 'Goede doel', url: '/shop/charity-selection' },
    { label: 'Gegevens', url: '/shop/shipping-info' },
  ];

  return (
    <div className="fixed bottom-0 text-xs w-full px-2 mb-1 space-x-2 flex justify-between">
      {steps.map((stepInfo, index) => (
        <Link
          key={index}
          to={stepInfo.url}
          className={`w-1/5 py-2 text-center border ${
            step >= index + 1
              ? 'text-white bg-SLblue border-background '
              : 'text-SLgray bg-background border-SLblue'
          }`}
          // Disable the link if the step is ahead of the current step
          disabled={index + 1 >= step}
          style={{ pointerEvents: index + 1 >= step ? 'none' : 'auto' }}
        >
          {`${index + 1}. ${stepInfo.label}`}
        </Link>
      ))}
    </div>
  );
};

export default StepIndicatorFooter;
