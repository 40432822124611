import React from 'react';

const BlogArticles = ({ articles }) => {
  return (
    <div className="flex flex-col">
      {articles.map((article, index) => (
        <div
          key={index}
          className="my-4 py-10 px-4 border border-SLgray overflow-hidden"
        >
          <div className="w-full flex space-between">
            <div className="p-4 flex-auto">
              <h2 className="text-2xl text-SLgray font-bold">
                {article.title}
              </h2>
            </div>
            <div className="p-4 flex-none">
              <div className="text-sm text-center bg-green-700 text-white font-bold py-1 px-3 rounded">
                {
                  article.metafields.find(
                    (metafield) => metafield.key === 'category',
                  )?.value
                }
              </div>
            </div>
          </div>
          <div className="w-full p-4 text-SLgray flex flex-start">
            <p className="text-SLgray">{article.content}</p>
            <div className="flex-none ml-4">
              <img
                className="w-36 h-auto border border-SLgray p-2 bg-white"
                src={article.image.originalSrc}
                alt={article.image.altText}
              />
            </div>
          </div>
          <div className="p-4">
            <a
              href={
                article.metafields.find((metafield) => metafield.key === 'url')
                  ?.value
              }
              target="_blank"
              rel="noopener noreferrer"
              className="bg-SLblue text-white font-bold py-2 px-4 rounded-md inline-block"
            >
              Lees meer op de website
            </a>
          </div>
        </div>
      ))}
      <div className="w-full h-60"></div>
    </div>
  );
};

export default BlogArticles;
