import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CharitySelectionBlog = ({
  id,
  title,
  content,
  imageUrl,
  metafields,
  handleSelectOption,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = (event) => {
    event.preventDefault();
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleClick = (event) => {
    // Get the radio button associated with this row
    const radioButton = event.currentTarget.querySelector(
      'input[type="radio"]',
    );
    // If the radio button exists, set its checked attribute to true
    if (radioButton) {
      radioButton.checked = true;
    }
    // Call the handleSelectBlog function to handle the selection logic
    handleSelectOption(id);
  };

  return (
    <div
      onClick={handleClick}
      className="mb-10 text-left border border-solid border-SLblue p-4 cursor-pointer bg-background"
    >
      <div className="flex items-center">
        <div className="flex-none mr-10">
          <input type="radio" name="selectOption" required />
        </div>
        <div className="flex-1">
          <strong>{title}</strong>
          <p>
            {expanded ? content : `${content.slice(0, 200)}`} ...{' '}
            <Link
              className="text-SLblue underline"
              onClick={handleToggleExpand}
            >
              {expanded ? '...lees minder' : 'lees meer'}
            </Link>
          </p>
        </div>
        <div className="flex-none ml-10 text-right">
          <div className="text-xs text-center bg-green-700 text-white font-bold py-1 px-3 mt-2 mb-8 rounded">
            {
              metafields.find((metafield) => metafield.key === 'category')
                ?.value
            }
          </div>
          <img
            className="m-auto inline-block"
            src={imageUrl}
            alt={title}
            width="100px"
          />
        </div>
      </div>
    </div>
  );
};

export default CharitySelectionBlog;
