import React from 'react';
import SanitizedHTML from '../components/sanitizeHTML';
import Menu from '../components/menu';
import Logo from '../components/logo';
import Footer from '../components/footer';
import BlogArticles from '../components/blogArticles';

const DynamicContentPage = ({ id, data, title, menu, blogArticles }) => {
  const OnWhichPageCharityBlogs = '113582276772';
  const pageID = getIDfromPageID(id);

  return (
    <>
      <Menu menu={menu} />
      <Logo size={'large'} />
      <div className="contentBackground w-full text-SLgray">
        <div className="container mx-auto flex justify-center">
          <div className="w-full text-SLgray md:w-60p lg:w-2/3 xl:w-3/5 mt-20">
            <div className="p-4">
              <h2 className="text-2xl font-bold mb-4">{title}</h2>
              <div className="content">
                <SanitizedHTML html={data} />
                {pageID === OnWhichPageCharityBlogs && (
                  <BlogArticles articles={blogArticles} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-64"></div>
      </div>
      <Footer />
    </>
  );
};

export default DynamicContentPage;

const getIDfromPageID = (url) => {
  // Split the URL string by '/'
  const parts = url.split('/');
  // Get the last part of the array
  const lastPart = parts[parts.length - 1];
  return lastPart;
};
