import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { autoLogoutIfTokenExpired } from './components/sessionManager';
import PrivateRoute from './components/privateRoute';
import Home from './pages/home';
import Signin from './pages/signin';
import Success from './pages/shop/success';
import Congratulations from './pages/shop/congratulations';
import AmountSelection from './pages/shop/amountSelection';
import SizeSelection from './pages/shop/sizeSelection';
import CharitySelection from './pages/shop/charitySelection';
import ShippingInfo from './pages/shop/shippingInfo';
import DataOverview from './pages/shop/dataOverview';
import Payment from './pages/shop/payment';
import DynamicContentPage from './pages/dynamicContentPage';
import useApi from './hooks/useApi';

const App = () => {
  const { sendRequest } = useApi();
  const [shopifyContent, setShopifyContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestMade, setRequestMade] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => autoLogoutIfTokenExpired(), []);

  useEffect(() => {
    const fetchMenuData = async () => {
      setRequestMade(true);
      try {
        const response = await sendRequest('/shopify_content');
        
        setShopifyContent(response);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch Shopify content');
        setLoading(false);
      }
    };

    if (!requestMade) {
      fetchMenuData();
    }
  }, [requestMade, sendRequest]);

  let pages,
    menu = [];
  if (shopifyContent != null) {
    pages = shopifyContent.menuPages.map((el, i) => {
      menu.push({ title: el.title, url: el.handle });
      return (
        <Route
          key={`${el.handle}_${i}`}
          path={`/${el.handle}`}
          element={
            <DynamicContentPage
              id={el.id}
              handle={el.handle}
              data={el.data}
              title={el.title}
              menu={menu}
              blogArticles={shopifyContent.blogArticles}
            />
          }
        />
      );
    });
  }

  return (
    <div className="flex flex-col items-center">
      <Router>
        {error && 'Error loading data!'}
        {loading && 'Loading data'}
        <Routes>
          <Route exact path="/" element={<Home menu={menu} />} />
          {shopifyContent != null && pages}
          <Route path="/sign-in" element={<Signin menu={menu} />} />
          <Route exact path="/shop/success" element={<Success menu={menu} />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route
              exact
              path="/shop/congratulations"
              element={<Congratulations />}
            />
            <Route
              exact
              path="/shop/amount-selection"
              element={<AmountSelection />}
            />
            <Route
              exact
              path="/shop/size-selection"
              element={<SizeSelection />}
            />
            <Route
              exact
              path="/shop/charity-selection"
              element={<CharitySelection />}
            />
            <Route
              exact
              path="/shop/shipping-info"
              element={<ShippingInfo />}
            />
            <Route
              exact
              path="/shop/data-overview"
              element={<DataOverview />}
            />
            <Route exact path="/shop/payment" element={<Payment />} />
          </Route>
        </Routes>
      </Router>
      <div className="fixed w-full h-screen bg-background -z-50"></div>
    </div>
  );
};

export default App;
