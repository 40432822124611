// Function to set token with expiration time and order ID in localStorage
export const setLocalStorage = (step, token, orderID, durationInHours) => {
  const expirationTime =
    new Date().getTime() + durationInHours * 60 * 60 * 1000;
  localStorage.setItem(
    'auth',
    JSON.stringify({ step, token, orderID, expirationTime }),
  );
};

// Function to check if the token has expired
export const isTokenExpired = () => {
  let storedData = null;
  if (localStorage.getItem('auth')) {
    storedData = JSON.parse(localStorage.getItem('auth'));
  }
  if (storedData && storedData.expirationTime) {
    return new Date().getTime() > storedData.expirationTime;
  }
};

// Function to automatically log out the user when the token expires
export const autoLogoutIfTokenExpired = () => {
  if (isTokenExpired()) {
    // Clear localStorage and perform logout
    localStorage.removeItem('auth');
    // Perform logout action (e.g., redirect to login page)
    console.log('Token expired. Logging out...');
  }
};
