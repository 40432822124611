import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useApi from '../hooks/useApi';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isValidToken, setIsValidToken] = useState(false);
  const [getToken, setGetToken] = useState(true);
  const { error, sendRequest } = useApi();

  useEffect(() => {
    const checkTokenValidity = async () => {
      let tokenJson;
      if (!localStorage.getItem('auth')) {
        <Navigate to="/sign-in" />;
      } else {
        tokenJson = JSON.parse(localStorage.getItem('auth')).token;
      }
      try {
        if (tokenJson && getToken) {
          const response = await sendRequest('/check_token', 'POST', {
            token: tokenJson,
          });
          setIsValidToken(response.success);
          setGetToken(false);
        }
      } catch (error) {
        console.error('Error checking token validity:', error.message);
        setIsValidToken(false);
      } finally {
        // prevent race conditions
        setGetToken(false);
      }
    };

    checkTokenValidity();
  }, [getToken, sendRequest]);

  // Render loading indicator while the token validity check is in progress
  if (getToken) {
    return <p>Loading...</p>;
  }

  // Render the appropriate content based on token validity and errors
  return isValidToken ? (
    <Outlet />
  ) : error ? (
    <p>{error}</p>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
